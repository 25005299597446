import React from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// UI
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thead: {
      "& th": {
        whiteSpace: "nowrap",
        wordBreak: "keep-all",
        border: "1px solid rgba(198, 209, 221)",
        background: "rgba(240, 242, 244)",
        padding: "15px 1px 15px 17px"
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "40px",
        paddingTop: "3.5px",
        paddingBottom: "2px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface HeadCell {
  id: string;
  label: string;
  hiddenSortIcon: boolean;
}

const headCells: HeadCell[] = [
  { id: "order_no", label: "table_order_no", hiddenSortIcon: false },
  {
    id: "dz_order_no",
    label: "table_order_dz_order_no",
    hiddenSortIcon: false,
  },
  {
    id: "order_entry_date",
    label: "table_order_entry_date",
    hiddenSortIcon: false,
  },
  {
    id: "request_ship_date",
    label: "table_request_shipdate",
    hiddenSortIcon: false,
  },
  { id: "customer_no", label: "table_customer_no", hiddenSortIcon: false },
  {
    id: "customer_po_no",
    label: "table_customer_po_no",
    hiddenSortIcon: false,
  },
  {
    id: "payment_term_code",
    label: "table_payment_term_code",
    hiddenSortIcon: false,
  },
  { id: "total_amount", label: "table_total_amount", hiddenSortIcon: false },
  { id: "order_approve_name", label: "table_approved", hiddenSortIcon: false },
  {
    id: "order_confirmation_name",
    label: "table_confirmed",
    hiddenSortIcon: false,
  },
  {
    id: "note",
    label: "order_header_note",
    hiddenSortIcon: false,
  },
  {
    id: "order_person_in_charge",
    label: "table_person_in_charge",
    hiddenSortIcon: false,
  },

  { id: "details", label: "table_details", hiddenSortIcon: true },
];

export const OrderSearchResultTableThead = (props: any) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const { t } = useLocale({ defaultPath: "modules.components.order" });

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead className={classes.thead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy.indexOf(headCell.id) > -1 ? order : false}
          >
            <TableSortLabel
              active={
                !headCell.hiddenSortIcon && orderBy.indexOf(headCell.id) > -1
              }
              direction={orderBy.indexOf(headCell.id) > -1 ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hiddenSortIcon}
            >
              {t(headCell.label)}
              {orderBy.indexOf(headCell.id) > -1 ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
