import React, { FC, useState, useEffect } from "react";
// components
import { AppContainer } from "../../modules/components/appContainer/AppContainer";
import { OrderDetailTable } from "./components/orderDetail.Table";
import { OrderDetailAmount } from "./components/orderDetail.Amount";
import { OrderDetailNote } from "./components/orderDetail.Note";
// import { OrderDetailButton } from "./components/orderDetail.Button";
import { OrderDetailModelWindow } from "./components/orderDetail.ModelWindow";
import { AlertMesssageBox } from "../../modules/components/alert/AlertMesssageBox";
// language
import { useLocale } from "../../modules/hooks/UseLocale";
// api
import api from "../../modules/api/Api";
// common
import { useCommonInfo } from "../../modules/hooks/CommonInfoProvider";
// methods
import {
  getApiResultStatus,
  illegalCharactersErrorMessage,
} from "../../modules/common.methods";
// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// route
import { useHistory } from "react-router-dom";
import { GetPrivateAppRoutePath, PathKey } from "../../pages/AppRoutes";
// permissions
import Permissions from "../../modules/hooks/Permissions";
import { useSecureHttpRequest } from "../../modules/hooks/SecureHttpRequestProvider";
import { Loading } from "../../modules/components/loading/Loading";
import { priceOutputBySign } from "../../modules/components/numberFormat/NumberFormat.Currency";
import {
  checkIllegalCharUnicode,
  checkIllegalCharResultUnicode,
} from "../../modules/hooks/UseValication";
import {
  discardChageDisabled,
} from "./methods/OrderDetail.Methods";

const NO_QUERY_STRING = "?orderNo=";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    singleButtonControl: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "517px",
    },
    buttonSetting: {
      marginLeft: "50px",
    },
  })
);

export const Page: FC = (props: any) => {
  const classes = useStyles();
  // start
  const [startFlg, setStartFlg] = useState(false);
  const [tableData, setTableData] = useState({
    order_line: [],
    order_confirmation: 0,
    updated_date: undefined,
    customer_po_no: undefined,
  });
  const [totalAmount, setTotalAmount] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [originalDetailData, setOriginalDetailData] = useState([]);

  const [diffFlg, setDiffFlg] = useState(false);
  const [orderNo, setOrderNo] = useState(0);
  const [personInChargeState, setPersonInChargeState] = useState([]);
  const [personInChargeNameState, setPersonInChargeNameState] = useState("");
  const [orderHeaderNote, setOrderHeaderNote] = useState("");
  const [personInChargeSubState, setPersonInChargeSubState] = useState("");
  const [personInChargeSubOldState, setPersonInChargeSubOldState] =
    useState("");

  // language
  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants.messages" });
  // alert
  const [errorMessageInfo, setErrorMessageInfo] = useState([]);

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [errorFlag, setErrorFlag] = useState(false);

  const [loginUserNameState, setLoginUserNameState] = useState("");
  const [loginUserSubState, setLoginUserSubState] = useState("");

  const [userPermisssionInfo, setUserPermisssionInfo] = useState({}) as any;

  const [customerNoState, setCustomerNoState] = useState("");
  const [warningMessageState, setWarningMessageState] = useState([]) as any;

  const [itemSuspendJudge, setItemSuspendJudge] = useState(false);
  const [customerClosedJudge, setCustomerClosedJudge] = useState(false);

  const [diffResultCurrentOriginal, setDiffResultCurrentOriginal] =
    useState(false);

  const [orderHeader, setOrderHeader] = useState({
    customer_po_no: "",
  });

  const [orderHeaderError, setOrderHeaderError] = useState({
    customer_po_no_error: "",
  });

  // loading
  const {
    setCommonInfo,
    getCommonConfig,
    getUserPermissions,
    setUserPermissions,
    getHotKey,
    setHotKey,
    getUserInfoRepair,
  } = useCommonInfo();

  const apiLoading = (flg: boolean) => {
    setCommonInfo("loadingFlg", flg);
  };
  const history = useHistory();

  const config = getCommonConfig() as any;
  let hotKeyInfo = getHotKey() as any;

  // permissions
  let userPermissions = getUserPermissions() as any;
  const { getUserPermissionsRe } = useSecureHttpRequest();
  const permissionsInfomation = new Permissions();

  // model
  const [modalOpenDiscard, setModalOpenDiscard] = useState(false);
  const [modalOpenSendDZ, setModalOpenSendDZ] = useState(false);
  const [modalOpenSuccessDz, setmMdalOpenSuccessDz] = useState(false);
  const [modalOpenSendBPCS, setModalOpenSendBPCS] = useState(false);
  const [modalOpenSuccessBPCS, setModalOpenSuccessBPCS] = useState(false);
  const [modalOpenReturn, setModalOpenReturn] = useState(false);
  // model close button
  const closeModelWindow = () => {
    if (modalOpenDiscard) {
      setModalOpenDiscard(false);
    }
    if (modalOpenSendDZ) {
      setModalOpenSendDZ(false);
    }
    if (modalOpenSuccessDz) {
      setmMdalOpenSuccessDz(false);
    }
    if (modalOpenSendBPCS) {
      setModalOpenSendBPCS(false);
    }
    if (modalOpenSuccessBPCS) {
      setModalOpenSuccessBPCS(false);
    }
    if (modalOpenReturn) {
      setModalOpenReturn(false);
    }
  };
  // model button event
  const sendData = () => {
    const path = GetPrivateAppRoutePath(PathKey.ORDER);
    if (modalOpenDiscard) {
      // discard change event
      setModalOpenDiscard(false);
      const detail: any = JSON.parse(JSON.stringify(tableData)).order_line;
      setorderHeaderValue("customer_po_no", tableData.customer_po_no);
      setDetailData(detail);
    } else if (modalOpenSendDZ) {
      const { param } = diffEvent(2);
      setModalOpenSendDZ(false);
      apiLoading(true);
      // AT1307
      api
        .updateOrderAPI(config, orderNo, param)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setmMdalOpenSuccessDz(true);
          }
        });
    } else if (modalOpenSuccessDz) {
      setmMdalOpenSuccessDz(false);
      history.push(path);
    } else if (modalOpenSendBPCS) {
      const { param } = diffEvent(1);
      setModalOpenSendBPCS(false);
      apiLoading(true);
      // AT1309
      api
        .updateOrderAPI(config, orderNo, param)
        .then(({ code, response }: { code: any; response: any }) => {
          apiLoading(false);
          if (!getApiResultStatus(code)) {
            errorMessage(response);
          } else {
            setModalOpenSuccessBPCS(true);
          }
        });
    } else if (modalOpenSuccessBPCS) {
      setModalOpenSuccessBPCS(false);
      history.push(path);
    } else if (modalOpenReturn) {
      setModalOpenReturn(false);
      history.push(path);
    }
  };

  // error message
  const errorMessage = (response: any) => {
    setAlertShow(true);
    const message = com(response.message_id, {
      param1: t(response.key),
    });
    setAlertMessage(message);
    setAlertType("error");
  };

  // init
  useEffect(() => {
    if (!startFlg && Object.keys(config).length > 0) {
      getDefaultApiList();
      let no: any = props.history.location.search.replace(NO_QUERY_STRING, "");
      if (no) {
        no = Number(no);
      }
      setOrderNo(no);
      getDetailInformation(no);
      setLoginUserInfo();
      setDefaultPersonInCharge();

      setStartFlg(true);
    } else {
      apiLoading(true);
    }
  }, [startFlg, config]);

  useEffect(() => {
    if (
      hotKeyInfo.handleF6OrderDetailSubmit &&
      tableData.order_confirmation === 0 &&
      userPermisssionInfo.csd_order_detail_submit_authFlg
    ) {
      submitHandle();
    }
    setHotKey("handleF6OrderDetailSubmit", false);
  }, [hotKeyInfo.handleF6OrderDetailSubmit]);

  const handleRefresh = () => {
    setAlertMessage("");
    setAlertShow(false);
    setPersonInChargeSubState("");
    setPersonInChargeSubOldState("");
    setErrorFlag(false);
    setErrorMessageInfo([]);

    setCustomerNoState("");
    setWarningMessageState([]);
    getDefaultApiList();
    getDetailInformation(orderNo);
    setLoginUserInfo();
    setDefaultPersonInCharge();
  };

  const onBlurPersonInCharge = () => {
    if (
      personInChargeSubState === "" &&
      personInChargeNameState.length > 0 &&
      personInChargeNameState !== loginUserNameState
    ) {
      setErrorFlag(true);
    } else {
      if (personInChargeSubState !== personInChargeSubOldState) {
        uploadPersonInCharge();
        setErrorFlag(false);
      }
    }
  };

  const setLoginUserInfo = () => {
    let logingUserName = "";
    let loginUserSub = "";

    // if (config.affiliate_cd === "MD") {
    if (config.middle_name && config.middle_name.length > 0) {
      logingUserName =
        config.given_name + " " + config.middle_name + " " + config.family_name;
    } else {
      logingUserName = config.given_name + " " + config.family_name;
    }
    // }

    if (config.sub === undefined) {
      loginUserSub = "";
    } else {
      loginUserSub = config.sub;
    }
    setLoginUserNameState(logingUserName);
    setLoginUserSubState(loginUserSub);
  };

  const setDefaultPersonInCharge = async () => {
    apiLoading(true);
    // AT1310
    await api
      .getReadOrderPersonInChargeListAPI(config)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);
        if (!getApiResultStatus(code)) {
          if (code !== 404) {
            errorMessage(response);
          }
        } else {
          setPersonInChargeState(JSON.parse(JSON.stringify(response)));
        }
      });
  };

  const updateSelectPersonInChargeStateBySub = (
    paraSub: any,
    personInchargeList: any
  ) => {
    if (personInchargeList && personInchargeList.length > 0) {
      for (let i = 0; i < personInchargeList.length; i++) {
        if (paraSub === personInchargeList[i].sub) {
          setPersonInChargeNameState(personInchargeList[i].name);
          setPersonInChargeSubState(personInchargeList[i].sub);
          return;
        }
      }
    }
    setPersonInChargeNameState("");
    setPersonInChargeSubState("");
  };

  const updateSelectPersonInChargeStateByName = (
    paraName: any,
    personInchargeList: any
  ) => {
    if (personInchargeList && personInchargeList.length > 0) {
      for (let i = 0; i < personInchargeList.length; i++) {
        if (paraName === personInchargeList[i].name) {
          setPersonInChargeNameState(personInchargeList[i].name);
          setPersonInChargeSubState(personInchargeList[i].sub);
          return;
        }
      }
    }
    setPersonInChargeNameState(paraName);
    setPersonInChargeSubState("");
  };

  const getDefaultApiList = () => {
    let apiList = [];
    let localUserPermissions = {};
    apiLoading(true);

    // permission
    if (!userPermissions.permission_group) {
      const api5 = getUserPermissionsRe().then((r: any) => {
        localUserPermissions = r.response;
        setUserPermissions(r.response);
      });
      apiList.push(api5);
    } else {
      localUserPermissions = userPermissions;
    }

    Promise.all(apiList).then(() => {
      apiLoading(false);
      initStart(localUserPermissions);
    });
  };

  const [customerPoNoAuthFlg, setCustomerPoNoAuthflg] = useState(false)

  const initStart = (localUserPermissions: any) => {
    permissionsInfomation.setPermissionsInfo(localUserPermissions);
    let userPermissionsInfo = permissionsInfomation.getPermissionsInfo();
    const userPermissionsListQtyPrice: any = process.env
      .REACT_APP_ORDER_DETAIL_QTY_PRICE
      ? process.env.REACT_APP_ORDER_DETAIL_QTY_PRICE.split(",")
      : [];
    const userPermissionsListCustomerPoNo: any = process.env
      .REACT_APP_ORDER_DETAIL_CUSTOMER_PO_NO
      ? process.env.REACT_APP_ORDER_DETAIL_CUSTOMER_PO_NO.split(",")
      : [];
    if (!userPermissionsListQtyPrice.includes(config.affiliate_cd)) {
      userPermissionsInfo.csd_order_detail_edit_qty_authFlg = false;
      userPermissionsInfo.csd_order_detail_edit_net_price_authFlg = false;
    }
    if (userPermissionsListCustomerPoNo.includes(config.affiliate_cd)) {
      setCustomerPoNoAuthflg(true)
    }
    setUserPermisssionInfo(userPermissionsInfo);
    const element = document.getElementById("btn_order_submit");
    if (element) {
      setTimeout(() => {
        element.focus();
      });
    }
    apiLoading(false);
  };

  // diff
  useEffect(() => {
    const { flg } = diffEvent();
    setDiffFlg(flg);
  }, [detailData]);

  interface paramInterface {
    order_qty?: undefined | number;
    net_price?: undefined | number;
    line_no: number;
    updated_date: string;
  }

  const diffEvent = (type: number = 0) => {
    let _flg: boolean = true;
    let _param: any = [];

    if (detailData.length > 0) {
      detailData.forEach((item: any, index: any) => {
        let f = true;
        const responseData: any = tableData.order_line[index];
        if (!item.free_goods_flag) {
          let _item: paramInterface = {
            order_qty: undefined,
            net_price: undefined,
            line_no: item.line_no,
            updated_date: item.updated_date,
          };
          if (Number(item.order_qty) !== Number(responseData.order_qty)) {
            f = false;
            _flg = false;
            _item.order_qty = Number(item.order_qty);
          }
          if (Number(item.net_price) !== Number(responseData.net_price)) {
            f = false;
            _flg = false;
            _item.net_price = Number(item.net_price);
          }
          if (!f) {
            _param.push(_item);
          }
        }
      });
    }

    const customer_po_no: any = customerPoNoAuthFlg ? orderHeader.customer_po_no : undefined;

    return {
      flg: _flg,
      param: {
        updated_date: tableData.updated_date,
        person_in_charge: loginUserNameState,
        person_in_charge_sub: loginUserSubState,
        order_approve: type,
        order_line: _param,
        customer_po_no: customer_po_no,
      },
    };
  };
  const getDetailInformation = (no: number) => {
    apiLoading(true);
    // AT1306
    api
      .getReadOrderAPI(config, no)
      .then(({ code, response }: { code: any; response: any }) => {
        if (!getApiResultStatus(code)) {
          errorMessage(response);
          apiLoading(false);
        } else {
          if (response) {

            setorderHeaderValue("customer_po_no", response.customer_po_no);

            if (response.order_line && response.order_line.length > 0) {
              response.order_line.forEach((item: any) => {
                item.editFlg =
                  item.item_no !== "FRACHT" && item.free_goods !== 1;
                item.onlyFlg = response.order_confirmation === 0;
                if (isNaN(item.net_price)) {
                  item.net_price = 0;
                } else {
                  item.net_price = Number(item.net_price);
                }
                item.net_price_format = priceOutputBySign(item.net_price);

                // getNote
                let _note_list = response.order_note;
                if (_note_list && _note_list.length > 0) {
                  let _note_message = _note_list.filter(
                    (subItem: any) =>
                      subItem.line_no === item.line_no &&
                      subItem.note_type === 1
                  );
                  if (_note_message.length > 0) {
                    item.line_note = _note_message[0].note;
                  }
                }
              });
            }

            if (
              response.order_line_original &&
              response.order_line_original.length > 0
            ) {
              response.order_line_original.forEach((item: any) => {
                item.editFlg =
                  item.item_no !== "FRACHT" && item.free_goods !== 1;
                item.onlyFlg = response.order_confirmation === 0;
                if (isNaN(item.net_price)) {
                  item.net_price = 0;
                } else {
                  item.net_price = Number(item.net_price);
                }
                item.net_price_format = priceOutputBySign(item.net_price);

                // getNote
                let _note_list = response.order_note_original;
                if (_note_list && _note_list.length > 0) {
                  let _note_message = _note_list.filter(
                    (subItem: any) =>
                      subItem.line_no === item.line_no &&
                      subItem.note_type === 1
                  );
                  if (_note_message.length > 0) {
                    item.line_note = _note_message[0].note;
                  }
                }
              });
            }

            let result = Object.assign({}, response);
            setData(result);

            apiLoading(false);
          } else {
            apiLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    if (customerNoState === "" || customerClosedJudge) {
      return;
    }

    api
      .getReadCustomerMasterAPI(customerNoState, config)
      .then(({ response, code }: { response: any; code: any }) => {
        if (!getApiResultStatus(code)) {
          let message = com("MS0004", {
            param1: t("customer_no"),
          });
        } else {
          if (response.customer_closed === true) {
            let message = com("MS0085", {
              param1: response.customer_no,
            });
            let warnMessageList = [message];

            if (warningMessageState.length > 0) {
              setWarningMessageState([warningMessageState, message]);
            } else {
              setWarningMessageState(warnMessageList);
            }
          }
        }
        setCustomerClosedJudge(true);
      });
  }, [customerNoState]);

  useEffect(() => {
    if (itemSuspendJudge && customerClosedJudge) {
      if (warningMessageState.length > 0) {
        let newMessage: any = (
          <>
            {warningMessageState.map((message: any, index: any) => {
              if (index === 0) {
                return <>{message}</>;
              } else {
                return (
                  <>
                    {" "}
                    <br /> {message}
                  </>
                );
              }
            })}
          </>
        );

        setAlertShow(true);
        setAlertMessage(newMessage);
        setAlertType("warning");
      }
      setItemSuspendJudge(false);
      setCustomerClosedJudge(false);
    }
  }, [itemSuspendJudge, customerClosedJudge, warningMessageState]);

  useEffect(() => {
    if (!customerClosedJudge) {
      return;
    }
    if (customerClosedJudge && detailData && detailData.length > 0) {
      const detail: any = JSON.parse(JSON.stringify(detailData));
      let newDetail = detail.filter((item: any) => {
        return item.free_goods === 0 && item.suspend_flg === 1;
      });

      newDetail.sort((item1: any, item2: any) => {
        if (item1.item_no === item2.item_no) {
          return 0;
        } else if (item1.item_no > item2.item_no) {
          return 1;
        } else {
          return -1;
        }
      });

      let itemNoList = [];
      let previousItem: any = null;
      let currentItem: any = null;

      for (let i = 0; i < newDetail.length; i++) {
        currentItem = newDetail[i];

        if (i === 0) {
          itemNoList.push(currentItem.item_no);
          // itemNoList[itemNoList.length] = currentItem.item_no;
        } else {
          if (previousItem.item_no !== currentItem.item_no) {
            itemNoList.push(currentItem.item_no);
            // itemNoList[itemNoList.length] = currentItem.item_no;
          }
        }
        previousItem = newDetail[i];
      }

      let warnMessageList = [] as any;

      if (itemNoList && itemNoList.length > 0) {
        for (let i = 0; i < itemNoList.length; i++) {
          let message: any = com("MS0052", {
            itemNum: itemNoList[i],
          });

          warnMessageList.push(message);
          if (warningMessageState.length > 0) {
            setWarningMessageState([warningMessageState, ...warnMessageList]);
          } else {
            setWarningMessageState(warnMessageList);
          }
        }
      }
      setItemSuspendJudge(true);
    }
  }, [detailData, customerClosedJudge]);

  const getValueCheck = (data: any) => data !== null && data !== undefined;

  const setData = (data: any) => {
    const responseData: any = JSON.parse(JSON.stringify(data));
    setTableData(responseData);

    setCustomerNoState(responseData.customer_no);
    setOrderHeaderNote(responseData.order_header_note);
    setPersonInChargeNameState(responseData.person_in_charge);
    setPersonInChargeSubState(responseData.person_in_charge_sub);

    setPersonInChargeSubOldState(responseData.person_in_charge_sub);

    const detail: any = JSON.parse(JSON.stringify(data)).order_line;

    if (detail && detail.length > 0) {
      detail.forEach((item: any) => {
        item.net_price = Number(item.net_price);
      });
      setDetailData(detail);
    }

    const original_detail: any = JSON.parse(
      JSON.stringify(data)
    ).order_line_original;

    if (original_detail && original_detail.length > 0) {
      original_detail.forEach((item: any) => {
        item.net_price = Number(item.net_price);
      });
      setOriginalDetailData(original_detail);
    }

    let checkResult = diffCurrentOriginal(
      data.order_line,
      data.order_line_original
    );

    setDiffResultCurrentOriginal(checkResult);
  };

  const diffCurrentOriginal = (order_line: any, order_line_original: any) => {
    if (order_line_original.length <= 0) {
      return true;
    }
    if (order_line.length !== order_line_original.length) {
      return false;
    }

    for (let i = 0; i < order_line.length; i++) {
      let orderLineObj = order_line[i];
      let orderLineOriginalObj = order_line_original[i];

      if (orderLineObj.item_no !== orderLineOriginalObj.item_no) {
        return false;
      } else if (orderLineObj.order_qty !== orderLineOriginalObj.order_qty) {
        return false;
      } else if (orderLineObj.net_price !== orderLineOriginalObj.net_price) {
        return false;
      }
    }
    return true;
  };

  // Discard Change event
  const discardChangeHandle = () => {
    if (!discardChageDisabled(diffFlg, tableData, orderHeader, customerPoNoAuthFlg)) {
      setModalOpenDiscard(true);
    }
  };

  // submit click event
  const submitHandle = () => {
    if (!diffFlg) {
      // to DZ
      setModalOpenSendDZ(true);
    } else {
      // to BPCS
      setModalOpenSendBPCS(true);
    }
  };

  const uploadPersonInCharge = () => {
    let postRequestData = {
      updated_date: tableData.updated_date,
      person_in_charge: personInChargeNameState,
      person_in_charge_sub: personInChargeSubState,
    };

    apiLoading(true);

    api
      .updateOrderPersonInChargeAPI(config, orderNo, postRequestData)
      .then(({ code, response }: { code: any; response: any }) => {
        apiLoading(false);

        if (!getApiResultStatus(code)) {
          errorMessage(response);
        } else {
          let newTableData = Object.assign({}, tableData, {
            updated_date: response.updated_date,
          });
          setTableData(newTableData);

          setPersonInChargeSubOldState(personInChargeSubState);
        }
      });
  };

  const returnHandle = () => {
    let flg = false;
    if (tableData.order_confirmation === 0) {
      if (!diffFlg) {
        flg = true;
      }
    }
    if (flg) {
      setModalOpenReturn(true);
    } else {
      const path = GetPrivateAppRoutePath(PathKey.ORDER);
      history.push(path);
    }
  };

  const attentionSendToDZ = (
    <p>
      {com("MS0061")}
      <br />
      {com("MS0062")}
    </p>
  );

  // set data
  const setorderHeaderValue = (
    key: string,
    value: any,
    subKey: string = ""
  ) => {
    if (subKey !== "") {
      setOrderHeader((prevState: any) => ({
        ...prevState,
        [key]: { ...prevState[key], [subKey]: value },
      }));
    } else {
      setOrderHeader((prevState: any) => ({ ...prevState, [key]: value }));
    }
  };

  // set error value
  const setOrderHeaderErrorListValue = (key: string, value: any) => {
    setOrderHeaderError((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getIllegalCharactersErrorMessage = (error_list: any) => {
    let _error_message = "";
    if (error_list.length > 0) {
      error_list.forEach((item: any, index: number) => {
        if (index !== 0) {
          _error_message += ",";
        }
        if (item.flg) {
          _error_message += com(`${item.message_id}`);
        } else {
          _error_message += item.message_id;
        }
      });
    }
    return _error_message;
  };

  const onBlurOfCustomerPONo = (value?: any) => {
    let noError = true;
    setOrderHeaderErrorListValue("customer_po_no_error", "");
    if (value || orderHeader.customer_po_no) {
      if (checkIllegalCharUnicode(value ? value : orderHeader.customer_po_no)) {
        let error_list = illegalCharactersErrorMessage(
          checkIllegalCharResultUnicode(
            value ? value : orderHeader.customer_po_no
          )
        );
        let message = com("MS0093", {
          param1: getIllegalCharactersErrorMessage(error_list),
        });
        setOrderHeaderErrorListValue("customer_po_no_error", message);
        noError = false;
      }
    }
    return noError;
  };

  const attentionSentToDZ = <p>{com("MS0066")}</p>;

  const BPCSMessage = () => {
    const _list: any = process.env
      .REACT_APP_ORDER_DETAIL_QTY_PRICE
      ? process.env.REACT_APP_ORDER_DETAIL_QTY_PRICE.split(",") : []
    if (!_list.includes(config.affiliate_cd)) {
      return 'MS0063_rollout'
    } else {
      return 'MS0063'
    }
  }

  const attentionSendToBPCS = (
    <p>
      {com(BPCSMessage())}
      <br />
      {com("MS0064")}
    </p>
  );

  const attentionSentToBPCS = <p>{com("MS0067")}</p>;

  const attentionLeavePage = (
    <p>
      {com("MS0059")}
      <br />
      {com("MS0065")}
    </p>
  );

  const attentionDiscard = (
    <p>
      {com("MS0059")}
      <br />
      {com("MS0060")}
    </p>
  );

  const contents = (
    <>
      <AlertMesssageBox
        show={alertShow}
        message={alertMessage}
        type={alertType}
      />
      <OrderDetailTable
        tableData={tableData}
        personInChargeState={personInChargeState}
        updateSelectPersonInChargeStateByName={
          updateSelectPersonInChargeStateByName
        }
        updateSelectPersonInChargeStateBySub={
          updateSelectPersonInChargeStateBySub
        }
        personInChargeNameState={personInChargeNameState}
        personInChargeSubState={personInChargeSubState}
        onBlurPersonInCharge={onBlurPersonInCharge}
        errorFlag={errorFlag}
        setErrorFlag={setErrorFlag}
        orderHeader={orderHeader}
        setorderHeaderValue={setorderHeaderValue}
        onBlurOfCustomerPONo={onBlurOfCustomerPONo}
        orderHeaderError={orderHeaderError}
        userPermisssionInfo={userPermisssionInfo}
        customerPoNoAuthFlg={customerPoNoAuthFlg}
      ></OrderDetailTable>
      {errorMessageInfo.length > 0 &&
        errorMessageInfo.map((item: any) => (
          <AlertMesssageBox show={true} message={item.message} type="error" />
        ))}

      <OrderDetailNote
        note={orderHeaderNote}
      ></OrderDetailNote>
      
      <OrderDetailAmount
        tableData={tableData}
        detailData={detailData}
        setDetailData={setDetailData}
        originalDetailData={originalDetailData}
        setErrorMessageInfo={setErrorMessageInfo}
        setTotalAmount={setTotalAmount}
        userPermisssionInfo={userPermisssionInfo}
        diffResultCurrentOriginal={diffResultCurrentOriginal}
        diffFlg={diffFlg}
        discardChangeHandle={discardChangeHandle}
        submitHandle={submitHandle}
        returnHandle={returnHandle}
        personInChargeSubState={personInChargeSubState}
        handleRefresh={handleRefresh}
        orderHeader={orderHeader}
        customerPoNoAuthFlg={customerPoNoAuthFlg}
      ></OrderDetailAmount>
      {/*
      <OrderDetailButton
        diffFlg={diffFlg}
        tableData={tableData}
        discardChangeHandle={discardChangeHandle}
        submitHandle={submitHandle}
        returnHandle={returnHandle}
        userPermisssionInfo={userPermisssionInfo}
        personInChargeSubState={personInChargeSubState}
        handleRefresh={handleRefresh}

      ></OrderDetailButton>
*/}

      <OrderDetailModelWindow
        attentionDiscard={attentionDiscard}
        modalOpenDiscard={modalOpenDiscard}
        attentionSendToDZ={attentionSendToDZ}
        modalOpenSendDZ={modalOpenSendDZ}
        attentionSentToDZ={attentionSentToDZ}
        modalOpenSuccessDz={modalOpenSuccessDz}
        attentionSendToBPCS={attentionSendToBPCS}
        modalOpenSendBPCS={modalOpenSendBPCS}
        attentionSentToBPCS={attentionSentToBPCS}
        modalOpenSuccessBPCS={modalOpenSuccessBPCS}
        attentionLeavePage={attentionLeavePage}
        modalOpenReturn={modalOpenReturn}
        sendData={sendData}
        closeModelWindow={closeModelWindow}
      />
    </>
  );

  return (
    <React.Fragment>
      <AppContainer contents={contents} />
    </React.Fragment>
  );
};
