import React from "react";
import clsx from "clsx";
// hook
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { date } from "../../../modules/components/numberFormat/NumberFormat.Date";
import { getCodeNameByNumber } from "../../../modules/common.methods";
import { currency } from "../../../modules/components/numberFormat/NumberFormat.Currency";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// root
import { Link } from "react-router-dom";
// UI
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// route
import { GetPrivateAppRoutePath, PathKey } from "../../../pages/AppRoutes";

type Order = "asc" | "desc";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "& td": {
        padding: "12px 0px 12px 0px",
        border: "1px solid rgba(198, 209, 221)",
      },
    },
    nowrap: {
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
    backgroundPink: {
      backgroundColor: "pink!important",
    },
    backgroundGray: {
      backgroundColor: "rgba(0,0,0,0.2)!important",
    },
    linkDetail:{
      textDecoration: "underline",
      cursor: "pointer",
      color: "#1800EA",
    },

  })
);

export const OrderSearchResultTableTbody = (props: any) => {
  const { pageInfo, order, orderBy, beforeToDetail } = props;
  const classes = useStyles();

  const { t } = useLocale({ defaultPath: "modules.components.order" });
  const { com } = useLocale({ defaultPath: "common.constants" });


  // sort
  const setSortData = (data: any, order: any, orderBys: any) => {
    console.log("Data: ",data);
    if (orderBys) {
      data.sort((a: any, b: any) => {
        let aValues = orderBys.map((orderBysub: any) => a[orderBysub]);
        let bValues = orderBys.map((orderBysub: any) => b[orderBysub]);
        let sort = 0;
        if (orderBys.length > 1) {
          if (
            bValues.toString().toUpperCase() < aValues.toString().toUpperCase()
          ) {
            sort = -1;
          } else if (
            bValues.toString().toUpperCase() > aValues.toString().toUpperCase()
          ) {
            sort = 1;
          }
        } else {
          if (
            Object.prototype.toString.call(bValues[0]) === "[object Number]"
          ) {
            if (bValues[0] === null && aValues[0] !== null) {
              sort = -1;
            } else if (bValues[0] !== null && aValues[0] === null) {
              sort = 1;
            } else if (bValues[0] < aValues[0]) {
              sort = -1;
            } else if (bValues[0] > aValues[0]) {
              sort = 1;
            }
          } else {
            if (!bValues[0]) {
              bValues[0] = "";
            }
            if (!aValues[0]) {
              aValues[0] = "";
            }
            if (
              bValues[0].toString().toUpperCase() <
              aValues[0].toString().toUpperCase()
            ) {
              sort = -1;
            } else if (
              bValues[0].toString().toUpperCase() >
              aValues[0].toString().toUpperCase()
            ) {
              sort = 1;
            }
          }
        }

        return order === "desc" ? sort : -sort;
      });
    }
    return data;
  };
  const setNoteLimit = (data: any, limit: any) => {
    if (data.length > limit) {
      return data.substring(0, limit) + '...';
    }
    return data;
  }
  return (
    <TableBody>
      {setSortData(props.data, order, orderBy)
        .slice(
          pageInfo.page * pageInfo.rowsPerPage,
          pageInfo.page * pageInfo.rowsPerPage + pageInfo.rowsPerPage
        )
        .map((row: any, index: number) => {
          const path =
            GetPrivateAppRoutePath(PathKey.ORDERDETAIL) +
            `?orderNo=${row.order_no}`;
          return (
            <TableRow
              className={clsx(
                classes.tableRow,
                row.order_approve === 2 &&
                row.order_confirmation === 0 &&
                classes.backgroundPink,
                row.order_approve === 2 &&
                row.order_confirmation === 2 &&
                classes.backgroundGray,
                row.order_approve === 1 &&
                row.order_confirmation === 1 &&
                classes.backgroundGray
              )}
              hover
              tabIndex={-1}
              key={row.no}
            >
              <TableCell className={classes.nowrap} align="center" data-testid={"resultList_order_no"+index}>
              <a onClick={()=>{beforeToDetail(path,row)}} className={classes.linkDetail}><span>{row.order_no}</span></a>
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.dz_order_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.order_entry_date || row.order_entry_date === ""
                  ? ""
                  : date(new Date(row.order_entry_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {!row.request_ship_date || row.request_ship_date === ""
                  ? ""
                  : date(new Date(row.request_ship_date).getTime())}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.customer_po_no}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.payment_term_code}
              </TableCell>
              <TableCell className={classes.nowrap} align="right">
                {!isNaN(row.total_amount) &&
                  currency(row.total_amount, row.currency)}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.order_approve_name}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.order_confirmation_name}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {setNoteLimit(row.order_header_note,50)}
              </TableCell>
              <TableCell className={classes.nowrap} align="center">
                {row.person_in_charge}
              </TableCell>
              <TableCell className={classes.nowrap} align="center" data-testid={"resultList_go"+index}>
                <a onClick={()=>{beforeToDetail(path,row)}}className={classes.linkDetail}><span>{t("go")}</span></a>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
