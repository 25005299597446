import { isTemplateMiddleOrTemplateTail } from "typescript";
import {
  getCodeNameByNumber,
  getSignByCSV,
} from "../../../modules/common.methods";
export const semicolon = ["MD"];

export const isAllEmpty = (data: any) => {
  let result = false;
  if (
    data.order_no === "" &&
    data.order_approved === "" &&
    data.order_confirmed === "" &&
    data.customer_no === "" &&
    data.order_entry_date_from === "" &&
    data.order_entry_date_to === ""
  ) {
    result = true;
  }
  return result;
};

export const isAllErrMsgmpty = (data: any) => {
  let result = false;
  if (
    data.customer_no_error === "" && 
    data.order_entry_date_from_error === "" && 
    data.order_entry_date_to_error === ""
  ) {
    result = true;
  }
  return result;
};

export const getSign = (config: any) => {
  return getSignByCSV(config.affiliate_cd);
};

export const getCSVTitleInfo = (t: any) => {
  return {
    order_no: { name: t("txt_order_no"), prop: "order_no", label: "Order No." },
    dz_order_no: { name: t("txt_dz_order_no"), prop: "dz_order_no", label: "DZ Order No." },
    order_entry_date: {
      name: t("txt_order_entry_date"),
      prop: "order_entry_date",
      label: "Order Entry Date",
    },
    request_ship_date: {
      name: t("txt_request_ship_date"),
      prop: "request_ship_date",
      label: "Request Shipment",
    },
    customer_no: {
      name: t("txt_customer_no"),
      prop: "customer_no",
      label: "Customer No.",
    },
    customer_po_no: {
      name: t("txt_customer_po_no"),
      prop: "customer_po_no",
      label: "Customer PO No.",
    },
    payment_term_code: {
      name: t("txt_payment_term_code"),
      prop: "payment_term_code",
      label: "Payment Term",
    },
    order_line: {
      name: t("txt_order_line"),
      prop: "order_line",
      label: "Order Line",
    },
    total_amount: {
      name: t("txt_total_amount"),
      prop: "total_amount",
      label: "Total Amount",
    },
    dz_user: {
      name: t("txt_dz_user"),
      prop: "dz_user",
      label: "DZ User",
    },
    person_in_charge: {
      name: t("txt_person_in_charge"),
      prop: "person_in_charge",
      label: "Person In Charge",
    },
    note:{
      name: t("order_header_note"),
      prop: "order_header_note",
      label: "Order Note",
    }
  };
};

export const getCSVTitle = (sign: any, t: any) => {
  let str = "";

  const _title: any = getCSVTitleInfo(t);

  let titleSize = Object.getOwnPropertyNames(_title).length;
  let itemIndex = 0;
  for (let item in _title) {
    itemIndex++;
    if (_title[item].name !== "") {
      if (itemIndex < titleSize) {
        str += _title[item].name + sign;
      } else {
        str += _title[item].name;
      }
    }
  }
  str += "\n";
  return str;
};

export const doQuotationMarks = (item: any) => {
  let str = String(item);
  return str.replace(/[\"]/g, '""');
};

// date length
export const date2length = (v: any, len: number) => {
  let _t: string = typeof v === "number" ? v.toString() : v;
  for (let i = 0; i < len; i++) {
    if (typeof _t === "string" && _t.length <= len - 1) {
      _t = "0" + _t;
    }
  }
  return _t;
};

// get CSV Date
export const getCSVDate = (date: any) => {
  const _date = new Date(date);
  let y = _date.getFullYear().toString();
  let m: any = date2length(_date.getMonth() + 1, 2);
  let d: any = date2length(_date.getDate(), 2);
  return `${y}-${m}-${d}`;
};

// get CSV contents
export const getCSVContents = (com: any, t: any, data: any, config: any) => {
  let str = "";
  const sign: any = getSign(config);
  const _title: any = getCSVTitleInfo(t);

  str += getCSVTitle(sign, t);
  if (data && data.length > 0) {
    data.forEach((item: any) => {
      for (let _key in _title) {
        let key: any = _title[_key].prop;
        let v = "";

        v = item[key] === null || item[key] === undefined ? "" : item[key];
        v = doQuotationMarks(v);

        // Order Entry Date
        if (_key === "order_entry_date") {
          if (v) {
            v = getCSVDate(v);
          } else {
            v = "";
          }
        } 
        // Request Shipment
        else if (_key === "request_ship_date") {
          if (v) {
            v = getCSVDate(v);
          } else {
            v = "";
          }
        }
        v = v === "" ? "-" : v;

        str += '"' + v + '"' + sign;
      }

      str += "\n";
    });
  }
  return str;
};

// get CSV file name
export const getCSVFileName = () => {
  const date = new Date();
  let y = date.getFullYear().toString();
  let m: any = date2length(date.getMonth() + 1, 2);
  let d: any = date2length(date.getDate(), 2);
  let hh = date2length(date.getHours(), 2);
  let mm = date2length(date.getMinutes(), 2);
  let ss = date2length(date.getSeconds(), 2);

  return y + m + d + hh + mm + ss;
};

