import React, { useState, useEffect } from "react";
// language
import { useLocale } from "../../../modules/hooks/UseLocale";
// UI
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    container:{
        paddingLeft: "20px",
        paddingRight: "25px",
    },
    box: {
        fontSize: "15px",
        marginTop: "20px",
        paddingLeft: "5px",
        borderBottom: "2px solid #CFCFCF",

    },
    root: {
        flexGrow: 1,
        "& .makeStyles-paper-22": {
            padding: "0px 16px",
            marginTop: "16px",
        },
    },
    multilineTextField: {
        readOnly: true,
        paddingLeft: "5px",
        width: "100%",
        "& fieldset": { border: 'none'},
        '& .MuiOutlinedInput-multiline': { padding: '5px 14px'}
      },
  })
);

export const OrderDetailNote = (props: any) => {

    const classes = useStyles();
    const {note} = props;
    const { t } = useLocale({
        defaultPath: "modules.components.order",
      });

    return(
        <div className={classes.container}>
            <div className={classes.box}>{t("order_header_note")}</div>
            <TextField          
                  value={note}
                  className={classes.multilineTextField}
                  variant="outlined"
                  multiline
                  rows={3}
                />
        </div>
      );
}